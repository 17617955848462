<template >
  <div class="warp"
       v-loading="Loading"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="main">
      <div class="header">
        <div>
          <p style="padding:6px 6px;float:right;"><span>当前角色：<span v-if="info.charac_no >= 1">{{info.charac_name}}</span> <a class="logout"
                 href="javascript:;"
                 @click="LoginDisplay = 'display:block'"
                 style="color:#989898;">《登录|切换》</a></span></p>
        </div>
        <div class="top">
          <p>{{info.on_off}}</p>
        </div>
        <div style="position:absolute;right:4px;top:42px;color:#5d5d5d;font-weight:600;font-family:monospace;">
          <p>{{info.opportunity}}</p>
          <p>{{info.figure}}</p>
        </div>
      </div>
      <div class="content"
           style="position:absolute;top:77px;">
        <div class="content_left">
          <ul v-if="info.data.length >= 1">
            <li v-for="(v, i) in info.data"
                :key="i"
                @click="smoke(v.grid,v.type)">
              <div v-if="v.ioc == ''">
                <img src="../../../public/static/plug/treasure/static/images/1.png">
              </div>
              <div v-else>
                <img :src="v.ioc">
              </div>
            </li>
          </ul>
          <ul v-else>
            <li v-for="o in 110"
                :key="o"><img src="../../../public/static/plug/treasure/static/images/1.png"></li>
          </ul>
          <div v-if="info.zhang == 1"
               style="text-align:center;">
            <img src="../../../public/static/plug/treasure/static/images/18.png">
          </div>
        </div>
        <div class="content_top">
          <div style="width:32%;display:inline-block;float:left;text-align:center;padding:52px 0px 2px 0px;">
            <span style="font-size:46px;font-weight:600;color:#bd850b;font-family:auto">{{info.treasure}}</span>
          </div>
          <div style="width:67%;display:inline-block;float:left;">
            <div style="width:42%;display:inline-block;">
              <div style="text-align:center;padding:72px 0px 22px 1px;">
                <img src="../../../public/static/plug/treasure/static/images/11.png">
              </div>
            </div>
            <div style="width:47%;display:inline-block;">
              <div style="position:absolute;top:60px;right:37px;display:grid;text-align:center;">
                <span style="font-size:12px;font-weight:600;color:#522929;margin-left:10px;">今日总发放</span>
                <span style="margin-left:10px;font-weight:600;color:#bd850b;font-size:20px;">{{info.dynamo}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="content_bottom">
          <div style="text-align:center;">
            <div class="tab tab-top"
                 style="display:inline-block;margin-top:16px;">
              <div class="tab-item toptab"
                   :class="{'active':info.zhang==1}">第一张奖励</div>
              <div class="tab-item toptab"
                   :class="{'active':info.zhang==2}">第二张奖励</div>
            </div>
          </div>
          <div class="page page-1 active"
               style="padding:0px 20px 0px 14px;">
            <ul class="list">
              <li v-for="(v, i) in info.itme1"
                  :key="i">
                <div class="act_left">
                  <img :src="v.ico">
                </div>
                <div class="act_right">
                  <p style="width:212px;color:#c496ef;">{{v.name}}<span style="float:right;padding-right:10px;color:#fff">x{{v.num}}</span></p>
                </div>
              </li>
            </ul>
          </div>
          <div class="page page-2"
               style="padding:0px 20px 0px 14px;">
            <ul class="list">
              <li v-for="(v, i) in info.itme2"
                  :key="i">
                <div class="act_left">
                  <img :src="v.ico">
                </div>
                <div class="act_right">
                  <p style="width:212px;color:#c496ef;">{{v.name}}<span style="float:right;padding-right:10px;color:#fff">x{{v.num}}</span></p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="alert">
      <!-- 弹出框 -->
      <div class="alert_bg"
           :style="MessageDisplay">
        <div class="alert_main">
          <div class="alert_title"><span>提示</span></div>
          <div class="alert_con">
            <div class="alert_com">
              <span>{{MessageMsg}}</span>
              <div class="btn"
                   @click="CloseAlert">关闭</div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>
      <!-- 弹出框end -->
      <!-- 登陆框 -->
      <div class="alert_bg"
           :style="LoginDisplay">
        <div class="alert_main"
             style="margin-top:86px;width:350px;">
          <div class="alert_title"><span>绑定|切换</span></div>
          <div class="alert_con">
            <div class="alert_com">
              <p style="margin-bottom:6px;display:flex;"><span style="padding:0px 0px 0px 42px;">游戏账号：</span><input type="text"
                       v-model="login_info.username"
                       class="service__input"></p>
              <p style="margin-bottom:6px;display:flex;"><span style="padding:0px 0px 0px 42px;">游戏密码：</span><input type="password"
                       v-model="login_info.password"
                       class="service__input"></p>
              <p style="margin-bottom:6px;display:flex;"><span style="padding:0px 0px 0px 42px;">绑定角色：</span>
                <select v-model="login_info.role"
                        style="width:154px;">
                  <option v-for="(itmec, i) in role_data"
                          :key="i"
                          :value="itmec.charac_no">{{itmec.charac_name}}</option>
                </select>
                <span style="background:#7478e4;padding:3px 10px;margin-left:2px;border-radius:4%;"
                      @click="getRole">获取</span>
              </p>
              <div>
                <span style="padding:0px 0px;color:#f90000;"><span style="display:inline-block;padding:0px 0px;">{{LoginError}}</span></span>
              </div>
              <div style="text-align:center;padding:10px 0px">
                <div class="btn"
                     @click="login"
                     style="margin:0px 0px;display:inline-block;">绑定</div>
                <div class="btn"
                     @click="LoginDisplay = 'display:none'"
                     style="margin:0px 0px;display:inline-block;">取消</div>
              </div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Vue from 'vue'
export default {
  name: "Cloud",
  data () {
    return {
      MessageDisplay: 'display:none', //弹出框显示
      LoginDisplay: 'display:none', //登陆弹出框
      LoginError: '',
      MessageMsg: '', //弹出框消息
      Loading: false,
      info: {
        m_id: 0, //账号uid
        charac_no: 0, //角色ID
        charac_name: '', //角色名称
        on_off: '',
        treasure: 0,
        data: [],
        zhang: 1,
        giving: 0,
        dynamo: 0,
        itme1: [],
        itme2: [],
        opportunity: "",
        figure: "",
      },
      login_info: {
        username: '',
        password: '',
        role: '',
      },
      role_data: [],
    };
  },
  created () {
    this.getInfo()
  },
  methods: {
    //获取基本配置信息
    getInfo () {
      this.Loading = true
      this.axios({
        // 默认请求方式为get
        method: 'post',
        url: Vue.prototype.config.webplugurl + '/treasure/getInfo',
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
        },
        responseType: 'json'
      }).then(response => {
        this.Loading = false
        if (response.data.code == 200) {
          this.info = response.data.info
        } else {
          this.PopMessage(true, response.data.msg)
        }
      }).catch(error => {
        this.Loading = false
        // 请求失败，
        console.log(error)
      });
    },
    //登陆
    login () {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true
      this.axios({
        // 默认请求方式为get
        method: 'post',
        url: Vue.prototype.config.webplugurl + '/PlugLogin',
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          username: this.login_info.username,
          password: this.login_info.password,
          role: this.login_info.role,
        },
        responseType: 'json'
      }).then(response => {
        this.Loading = false
        if (response.data.code == 200) {
          this.LoginDisplay = 'display:none'
          this.getInfo()
        } else {
          this.LoginError = response.data.msg
        }
      }).catch(error => {
        this.Loading = false
        // 请求失败，
        console.log(error)
      });
    },
    //获取角色
    getRole () {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true
      this.axios({
        // 默认请求方式为get
        method: 'post',
        url: Vue.prototype.config.webplugurl + '/getRole',
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          username: this.login_info.username,
          password: this.login_info.password,
        },
        responseType: 'json'
      }).then(response => {
        this.Loading = false
        if (response.data.code == 200) {
          this.role_data = response.data.info
        } else {
          this.LoginError = response.data.msg
        }
      }).catch(error => {
        this.Loading = false
        // 请求失败，
        console.log(error)
      });
    },
    //翻奖
    smoke (grid, gridtypes) {
      if (this.Loading == true) {
        return false
      }
      this.Loading = true
      this.axios({
        // 默认请求方式为get
        method: 'post',
        url: Vue.prototype.config.webplugurl + '/treasure/TreasureSmoke',
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          grid: grid,
          gridtypes: gridtypes,
        },
        responseType: 'json'
      }).then(response => {
        this.Loading = false
        if (response.data.code == 200) {
          this.PopMessage(true, response.data.msg)
        } else {
          this.PopMessage(true, response.data.msg)
        }
      }).catch(error => {
        this.Loading = false
        // 请求失败，
        console.log(error)
      });
    },
    //弹出框状态
    PopMessage (type, msg) {
      this.MessageDisplay = "display:none"
      this.MessageMsg = msg
      if (type == true) {
        this.MessageDisplay = "display:block"
      } else {
        this.getInfo()
      }
    },
    //关闭窗口
    CloseAlert () {
      this.PopMessage(false, '')
    },
  },
};
$(function () {

  $('.toptab').click(function () {
    var index = $(this).index();
    $('.page').removeClass('active');
    $('.toptab').removeClass('active');
    $(this).addClass('active');
    $('.page').eq(index).addClass('active');
  });
  $('.midtab').click(function () {
    var index = $(this).index();
    $('.quest-page-item').removeClass('active');
    $('.midtab').removeClass('active');
    $(this).addClass('active');
    $('.quest-page-item').eq(index).addClass('active');
  });
});
</script>

<style scoped>
@import "../../../public/static/plug/treasure/static/css/game.css";
</style>
<style>
#root,
body,
html {
  min-width: 0px;
}
</style>